html {
  font-size: 16px; /* sets root em (rem) scaling */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 40px auto;
  max-width: 650px;
  line-height: 1.6;
  font-size: 18px;
  color: #444040;
  padding: 0 10px;
  font-family: sans-serif; 
  background-color: #ffffff; /* I prefer #eee (I'm just letting it match the landing page image background for now) */
}

a:hover {
  font-weight: bold;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23551a8b' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}

h1 { font-size: 2em; }
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  color: #041430;
}
p > strong { color: #345; }

nav {
  background: radial-gradient(circle at 30%, #ffffffff, #ffffffff, #ffffffff, #ffffffe0, #00000000, #00000000);
  border-radius: 1.5rem;
  position: fixed;
  top: .5rem;
  left: 0;
  width: 7rem;
  padding: .5rem;
  z-index: 10;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  transition: all .4s linear;
}
@media (min-width: 900px) { nav { width: 20vw; font-size: 3.2vw; } }
nav > a {
  display: block;
  font-weight: bold;
}
.nav-current {
  color: #333;
  margin: 0 auto;
}
.nav-hidden { filter: blur(8rem) }

@keyframes pushMe {
  2% {color: #e5193b; transform: rotate(3deg) scaleX(1.05)}
  4% {transform: rotate(-6deg) scaleX(1.1)}
  6% {transform: rotate(9deg) scaleX(1.15)}
  8% {transform: rotate(-12deg) scaleX(1.2)}
  10% {transform: rotate(0deg) scaleX(1)}
}

.cta {
  -webkit-animation: pushMe 4s infinite;
  animation: pushMe 4s infinite;
  outline: none;
  font-family: 'helvetica neue' sans-serif;
  font-size: 2em;
  color: white;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.7), 1px 1px 1px rgba(255, 255, 255, 0.3);
  display: block;
  margin: 1rem 0;
  padding: 23px 37px 25px 35px;
  cursor: pointer;
  background-color: #2e050c; /* #2e050c */
  background-image: linear-gradient(273deg, #eb4763 30%, #e61a3c 40%);
  border: none;
  border-radius: 16px;
  box-shadow: inset 0px 0px 1px 1px rgba(138, 15, 36, 0.9), inset 0px 0px 2px 3px rgba(230, 26, 60, 0.9), inset 1px 1px 1px 4px rgba(255, 255, 255, 0.8), inset 0px 0px 2px 7px rgba(235, 71, 99, 0.8), inset 0px 0px 4px 10px rgba(230, 26, 60, 0.9), 8px 10px 2px 6px rgba(92, 10, 24, 0.55), 0px 0px 3px 2px rgba(184, 20, 48, 0.9), 0px 0px 2px 6px rgba(230, 26, 60, 0.9), -1px -1px 1px 6px rgba(255, 255, 255, 0.9), 0px 0px 2px 11px rgba(230, 26, 60, 0.9), 0px 0px 1px 12px rgba(184, 20, 48, 0.9), 1px 3px 14px 14px rgba(0, 0, 0, 0.4);
}

.cta:hover {
  animation-play-state: paused;
  color: #2e050c;
}

.cta:active {
  color: #d9d9d9;
  padding: 26px 34px 22px 38px;
  background-image: linear-gradient(273deg, #e61a3c 50%, #e8304f 60%);
  box-shadow: inset 3px 4px 3px 2px rgba(92, 10, 24, 0.55), inset 0px 0px 1px 1px rgba(138, 15, 36, 0.9), inset -1px -1px 2px 3px rgba(230, 26, 60, 0.9), inset -2px -2px 1px 3px rgba(255, 255, 255, 0.8), inset 0px 0px 2px 7px rgba(235, 71, 99, 0.8), inset 0px 0px 3px 10px rgba(230, 26, 60, 0.9), 0px 0px 3px 2px rgba(184, 20, 48, 0.9), 0px 0px 2px 6px rgba(230, 26, 60, 0.9), -1px -1px 1px 6px rgba(255, 255, 255, 0.9), 0px 0px 2px 11px rgba(230, 26, 60, 0.9), 0px 0px 1px 12px rgba(184, 20, 48, 0.9), 1px 3px 14px 14px rgba(0, 0, 0, 0.4);
}

.feedback-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.hueg { filter: hue-rotate(120deg); }
.feedback {
  outline: none;
  font-family: 'helvetica neue' sans-serif;
  font-size: 1.5rem;
  color: white;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.7), 1px 1px 1px rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  background-color: #2e050c; /* #2e050c */
  background-image: linear-gradient(273deg, #eb4763 30%, #e61a3c 40%);
  border: none;
  border-radius: 16px;
  box-shadow: inset 0px 0px 1px 1px rgba(138, 15, 36, 0.9), inset 0px 0px 2px 3px rgba(230, 26, 60, 0.9), inset 1px 1px 1px 4px rgba(255, 255, 255, 0.8), inset 0px 0px 2px 7px rgba(235, 71, 99, 0.8), inset 0px 0px 4px 10px rgba(230, 26, 60, 0.9), 8px 10px 2px 6px rgba(92, 10, 24, 0.55), 0px 0px 3px 2px rgba(184, 20, 48, 0.9), 0px 0px 2px 6px rgba(230, 26, 60, 0.9), -1px -1px 1px 6px rgba(255, 255, 255, 0.9), 0px 0px 2px 11px rgba(230, 26, 60, 0.9), 0px 0px 1px 12px rgba(184, 20, 48, 0.9), 1px 3px 14px 14px rgba(0, 0, 0, 0.4);
}

.feedback:hover { color: #2e050c; }

.feedback:active {
/*.feedback-input:checked + .feedback {*/
  color: #d9d9d9;
  padding: 26px 34px 22px 38px;
  background-image: linear-gradient(273deg, #e61a3c 50%, #e8304f 60%);
  box-shadow: inset 3px 4px 3px 2px rgba(92, 10, 24, 0.55), inset 0px 0px 1px 1px rgba(138, 15, 36, 0.9), inset -1px -1px 2px 3px rgba(230, 26, 60, 0.9), inset -2px -2px 1px 3px rgba(255, 255, 255, 0.8), inset 0px 0px 2px 7px rgba(235, 71, 99, 0.8), inset 0px 0px 3px 10px rgba(230, 26, 60, 0.9), 0px 0px 3px 2px rgba(184, 20, 48, 0.9), 0px 0px 2px 6px rgba(230, 26, 60, 0.9), -1px -1px 1px 6px rgba(255, 255, 255, 0.9), 0px 0px 2px 11px rgba(230, 26, 60, 0.9), 0px 0px 1px 12px rgba(184, 20, 48, 0.9), 1px 3px 14px 14px rgba(0, 0, 0, 0.4);
}

/* span {color: #1a6157;} */

.dizzy-a { animation: orbit 4s linear infinite; }
.dizzy-b { animation: orbit 4s linear -2s reverse infinite; }
@keyframes orbit {
    from { transform: rotate(0deg) translateX(2px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(2px) rotate(-360deg); }
}

.quote {
    animation: quote 4s ease-in-out infinite;
}
@keyframes quote {
    0%, 100% { opacity: .3; transform: translateX(-2px); }
    50% { opacity: .8; transform: translateX(2px); }
}

/* Confetti */
#confetti {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

[class|="confetti"] { position: absolute; }

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 149 {
  $w: random(16);
  $l: random(200);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(2+random()+"s") unquote(3*random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(50)+"%");
    }
  }
}

@media screen and (min-width: 768px) { .rwd-break { display: none; } }

@keyframes twinkle {
    0% {   transform: translateX(4rem) rotate(30deg); }
    50% {  transform: translateX(-4rem) rotate(-30deg); }
    100% { transform: translateX(4rem) rotate(30deg); }
}

@keyframes popup {
    0% {  transform: translateY(-500%) rotate(-90deg); }
    100% {  transform: translateY(100%) rotate(-90deg); }
}

.post-excerpt { border-left: solid 1px #bbb; padding-bottom: 1px; padding-left: .5rem; }
.post-excerpt:after {
    content: "";
    position: absolute;
    border-top: solid 1px #bbb;
    height: 1px;
    width: 1rem;
    margin-left: -.5rem;
}
